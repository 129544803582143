<template>
  <div>
    <el-table
      :data="data"
      style="width: 100%"
      size="small"
      v-loading="tableLoading"
    >
      <el-table-column
        prop="index_"
        :label="$t('withdraw_record_title1')"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="currency"
        :label="$t('withdraw_record_title2')"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="amount"
        :label="$t('initlate_exchange_text7')"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="accountName"
        :label="$t('do_payment_title11')"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="accountNo"
        :label="$t('withdraw_record_title3')"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="feeCurrency"
        width="180"
        :label="$t('withdraw_title11')"
      ></el-table-column>
      <el-table-column
        prop="fee"
        :label="$t('withdraw_title13')"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="date"
        :label="$t('withdraw_record_title4')"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="status"
        :label="$t('withdraw_record_title5')"
        width="140"
      >
        <template slot-scope="scope">
          {{
            scope.row.status === 0
              ? $t('withdraw_record_title6')
              : scope.row.status === 1
              ? $t('withdraw_record_title7')
              : scope.row.status === 2
              ? $t('withdraw_record_title8')
              : scope.row.status === 3
              ? $t('withdraw_record_title9')
              : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('home_page_title8')"
        width="160"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="handleDownload(scope)"
            v-if="scope.row.status === 2"
          >
            {{ $t('withdraw_record_title10') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="float: right; margin-top: 20px">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next"
        :total="pages.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { previewPdf } from 'views/main/withdraw/api';

export default {
  name: 'withdraw-record',
  data() {
    return {};
  },
  methods: {
    async handleDownload({ row }) {
      await previewPdf({
        tradeId: row.tradeId,
      });
      this.$message.success(this.$t('check_detail_msg11'));
    },
    handleCurrentChange(pageNum) {
      this.$emit('handlePageChange', pageNum);
    },
  },
  props: {
    tableLoading: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    pages: {
      type: Object,
      default: () => ({ pageSize: 10, pageNum: 1, total: 0 }),
    },
  },
};
</script>
