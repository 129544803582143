<template>
  <div>
    <div v-if="isAdd === false">
      <div class="top">
        <span>{{ $t('bind_account_title1') }}</span>
        <p>
          <el-button type="primary" size="small" @click="handleClickAdd(1)">
            {{ $t('bind_account_title2') }}
          </el-button>
          &nbsp;
          <el-button type="primary" size="small" @click="handleClickAdd(2)">
            {{ $t('bind_account_title3') }}
          </el-button>
        </p>
      </div>
      <el-table
        :data="accountList"
        style="width: 100%"
        v-loading="tableLoading"
      >
        <el-table-column
          prop="accountName"
          :label="$t('bind_account_title4')"
          fixed="left"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="accountNo"
          :label="$t('bind_account_title5')"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="accountType"
          :label="$t('bind_account_title6')"
          width="140"
        >
          <template slot-scope="scope">
            {{
              scope.row.accountType && scope.row.accountType.toString() === '1'
                ? $t('bind_account_title1')
                : scope.row.accountType &&
                  scope.row.accountType.toString() === '2'
                ? $t('bind_account_title7')
                : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="accountBank"
          :label="$t('bind_account_title8')"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="subBank"
          :label="$t('bind_account_title9')"
        ></el-table-column>
        <el-table-column
          width="180"
          prop="swiftCode"
          label="Swift Code"
        ></el-table-column>
        <el-table-column
          width="180"
          prop="bankAddress"
          :label="$t('home_page_title30')"
        ></el-table-column>
        <el-table-column
          prop="area"
          :label="$t('bind_account_title10')"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="address"
          width="300"
          :label="$t('incoming_director_form24')"
        ></el-table-column>
        <el-table-column
          prop="nickName"
          :label="$t('bind_account_title11')"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="status"
          :label="$t('system_settings_text22')"
          width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.status === 0
                ? $t('withdraw_record_title6')
                : scope.row.status === 1
                ? $t('bind_account_title12')
                : scope.row.status === 2
                ? $t('bind_account_title13')
                : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="action"
          :label="$t('home_page_title8')"
          fixed="right"
          width="120"
        >
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('bind_account_title14')"
              @confirm="handleDelete(scope.row)"
            >
              <a slot="reference">{{ $t('incoming_beneficiary_form5') }}</a>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div style="float: right; margin-top: 20px">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
          :total="withdrawPages['total']"
        ></el-pagination>
      </div>
    </div>
    <div v-else>
      <div class="add-account-info">
        <el-form
          ref="form"
          label-position="right"
          label-width="230px"
          :rules="rules"
          size="small"
          :model="account_form"
        >
          <div class="header">
            <p>
              {{
                `${$t('bind_account_title15')}${
                  currentType === 1
                    ? $t('home_page_title10')
                    : $t('bind_account_title17')
                }${$t('bind_account_title16')}`
              }}
            </p>
            <p>
              {{
                `${$t('bind_account_title18')}${
                  currentType === 1
                    ? $t('home_page_title10')
                    : $t('bind_account_title17')
                }${$t('bind_account_title19')}`
              }}
            </p>
          </div>
          <el-form-item :label="$t('withdraw_title3')" prop="accountName">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.accountName"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item :label="$t('bind_account_title20')" prop="accountNo">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.accountNo"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="Swift Code" prop="swiftCode">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.swiftCode"
              style="width: 200px"
              :maxlength="11"
            />
          </el-form-item>
          <el-form-item :label="$t('home_page_title29')" prop="accountBank">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.accountBank"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item :label="$t('bind_account_title21')" prop="bankAddress">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.bankAddress"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item :label="$t('do_payment_title14')" prop="middleBankName">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.middleBankName"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item
            :label="`${$t('do_payment_title15')}SWIFT`"
            prop="middleSwiftCode"
          >
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.middleSwiftCode"
              style="width: 200px"
            />
          </el-form-item>
          <!--          下面的表单-->
          <div class="header">
            <p>{{ $t('bind_account_title22') }}</p>
            <p>{{ $t('bind_account_title23') }}</p>
          </div>
          <el-form-item :label="$t('service_fee_query_title7')" required>
            <el-select :value="$t('bind_account_title24')" style="width: 200px">
              <el-option :value="$t('bind_account_title24')">
                {{ $t('bind_account_title24') }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('bind_account_title25')" prop="receiverName">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.receiverName"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item :label="$t('bind_account_title26')" prop="area">
            <el-select
              v-model="account_form.area"
              filterable
              style="width: 200px"
              :placeholder="$t('COMMON_ERR4')"
            >
              <el-option
                v-for="item in allCountries"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('bind_account_title27')" prop="address">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.address"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item :label="$t('bind_account_title28')" prop="nickName">
            <el-input
              :placeholder="$t('COMMON_ERR1')"
              v-model="account_form.nickName"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item
            :label="
              currentType === 1
                ? $t('bind_account_title29')
                : $t('bind_account_title30')
            "
            prop="fileUrl"
            :rules="{
              required: currentType !== 1,
              message: $t('COMMON_ERR3'),
              trigger: 'change',
            }"
          >
            <a-upload
              :action="uploadFileUrl"
              :file-list="uploadFileList"
              :headers="{ Authorization: storage.getItem('token') }"
              @change="handleUploadChange"
            >
              <el-button size="small">
                {{ $t('incoming_company_upload1') }}
              </el-button>
            </a-upload>
          </el-form-item>
        </el-form>
      </div>
      <el-button @click="cancel">{{ $t('bind_account_title31') }}</el-button>
      <el-button @click="handleSubmit" type="primary" :loading="submit_loading">
        {{ $t('incoming_button2') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import mixins from './mixins';
import { uploadFileUrl, getAddAccount } from 'views/main/withdraw/api';
import storage from '@/utils/storage';

export default {
  name: 'bind-account',
  computed: {
    storage() {
      return storage;
    },
  },
  mixins: [mixins],
  data() {
    return {
      uploadFileUrl,
      account_form: {
        accountName: '',
        accountNo: '',
        swiftCode: '',
        accountBank: '',
        bankAddress: '',
        area: '',
        address: '', // 详细地址
        nickName: '', // 昵称
        fileUrl: '',
        receiverName: '', //收款方名称
        middleBankName: '', // 中间行名称
        middleSwiftCode: '', // 中间行swift
      },
      isAdd: false,
      uploadFileList: [],
      fileUrl: '',
      submit_loading: false,
    };
  },
  props: {
    withdrawPages: {
      type: Object,
      default: () => ({
        pageSize: 10,
        pageNum: 1,
        total: 0,
      }),
    },
    tableLoading: {
      type: Boolean,
      default: () => false,
    },
    token: {
      type: String,
      default: () => '',
    },
    allCountries: {
      type: Array,
      default: () => [],
    },
    accountList: {
      type: Array,
      default: () => null,
    },
  },
  methods: {
    cancel() {
      this.$refs.form.resetFields();
      this.uploadFileList = [];
      this.isAdd = false;
    },
    handleCurrentChange(p) {
      this.$emit('handleBindPageChange', p);
    },
    handleSubmit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          // if (!this.uploadFileList.length) {
          //   this.$message.info('请上传银行开户许可证');
          //   return;
          // }
          const _params = {
            ...this.account_form,
            fileUrl: this.fileUrl,
            accountType: this.currentType,
          };
          try {
            this.submit_loading = true;
            await getAddAccount(_params);
            this.$message.success(this.$t('bind_account_title32'));
            this.submit_loading = false;
            this.$refs['form'].resetFields();
            this.isAdd = false;
            this.handleCurrentChange(1);
          } catch (err) {
            console.error(err);
          } finally {
            this.submit_loading = false;
            this.uploadFileList = [];
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleUploadChange(info) {
      if (info) {
        this.uploadFileList = [];
        let _uploadFileList = [...info.fileList];
        _uploadFileList = _uploadFileList.slice(-1);
        this.uploadFileList = _uploadFileList;
        this.fileUrl = this.uploadFileList[0].response?.dataSet.path;
        this.account_form.fileUrl = this.fileUrl;
      }
    },
    handleDelete(obj) {
      const _params = {
        accountId: obj.id,
      };
      this.$emit('handleDelete', _params);
    },
    handleClickAdd(type) {
      this.currentType = type;
      this.isAdd = true;
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > span {
    font-size: 18px;
    font-weight: 500;
  }
}
.add-account-info {
  .header {
    & > p:first-child {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
    & > p:nth-child(2) {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

::v-deep.ant-upload-list {
  width: 200px;
}
</style>
