<template>
  <div class="cross-capital">
    <div v-if="isShowExitFunds">
      <el-form>
        <el-form-item>
          资金跨境指中国大陆与其他国家地区之间的资金流动
        </el-form-item>
      </el-form>
      <div style="display: flex">
        <!-- <el-card class="box-card" style="margin-right: 10px">
          <div slot="header" class="clearfix">
            <el-button type="primary" @click="handleFundCross">
              资金出境
            </el-button>
          </div>
          <p>
            用于以人民币跨境的方式出境，完成出境后可在本平台进行换汇成其他货币进行支付
          </p>
          <p>
            支持的行业:
            进口电商、出口电商、留学教育、航空机票酒店住宿、旅游门票、国际物流、出境医疗
            一般贸易、海外租房、国际展览、国际会议
            海外广告、艺术品拍卖网站/平台、 境外租赁
          </p>
        </el-card> -->
        <el-card class="box-card" style="flex: 1; margin-left: 10px">
          <div slot="header" class="clearfix">
            <el-button type="primary" @click="$router.push('/funds-inflow')">
              资金入境
            </el-button>
          </div>
          <p>用于以人民币跨境的方式入境，完成结汇后可在本平台进行代付下发。</p>
          <p>支持的行业： 出口电商、国际物流、一般贸易等</p>
        </el-card>
      </div>
    </div>
    <div v-else>
      <h4>资金出境</h4>
      <el-form ref="submitForm" inline size="small" :model="submitForm">
        <el-form-item label="交易流水号:" prop="">
          <a-input v-model="submitForm.serialNumber"></a-input>
        </el-form-item>
        <el-form-item label="收款人名称:" prop="">
          <a-input v-model="submitForm.payee"></a-input>
        </el-form-item>
        <el-form-item label="付款金额:" prop="">
          <a-input
            style="width: 45%"
            placeholder="最小值"
            v-model="submitForm.paymentMin"
          ></a-input>
          ~
          <a-input
            style="width: 45%"
            placeholder="最大值"
            v-model="submitForm.paymentMax"
          ></a-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button type="primary" @click="handleClickSearch">查询</el-button>
          <el-button type="primary" @click="showDetail = true">
            上传交易明细
          </el-button>
          <el-button type="primary" @click="cancel">返回</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" style="width: 100%" v-loading="tableLoading">
        <el-table-column prop="index_" fixed width="80" label="序号" />
        <el-table-column prop="createTime" label="提交时间" width="200" />
        <el-table-column prop="serialNumber" label="交易流水号" width="200" />
        <el-table-column prop="fileUrl" label="文件名" width="200">
          <template slot-scope="scope">
            <a :href="downloadFileUrl(scope.row.fileUrl.split('/')[4])">
              {{ scope.row.detailFileName }}
            </a>
          </template>
        </el-table-column>
        <el-table-column prop="detailAmount" label="明细金额" width="180">
          <template slot-scope="scope">
            {{ 'CNY     ' + scope.row.detailAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="fee" label="手续费" width="180">
          <template slot-scope="scope">
            {{ 'CNY     ' + scope.row.fee }}
          </template>
        </el-table-column>
        <el-table-column prop="payee" label="收款人" width="200" />
        <el-table-column prop="payment" label="付款金额" width="140">
          <template slot-scope="scope">
            {{ `${scope.row.paymentCurrency}     ` + scope.row.payment }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="180">
          <template slot-scope="scope">
            {{
              statusList.find(item => item.value === scope.row.status).text ||
              ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="completeDate" label="完成时间" width="200" />
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 2"
              type="text"
              @click="handleClickTopUp(scope)"
            >
              充值
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="pagination.total"
        style="margin-top: 20px"
      ></el-pagination>
      <el-dialog
        :close-on-click-modal="false"
        :visible="showDetail"
        title="上传交易明细"
        @close="handleShowClose"
        width="1000px"
      >
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="140px"
          :rules="rules"
        >
          <el-form-item label="选择最终收款人:" prop="payee">
            <el-select
              clearable
              placeholder="请选择"
              style="width: 100%"
              v-model="ruleForm.payee"
            >
              <el-option
                v-for="item in accountList"
                :key="item.id"
                :value="item.id"
                :label="item.accountName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择付款币种:" prop="paymentCurrency">
            <el-select
              clearable
              placeholder="请选择"
              style="width: 100%"
              v-model="ruleForm.paymentCurrency"
            >
              <el-option label="USD" value="USD"></el-option>
              <el-option label="CHN" value="CHN"></el-option>
              <el-option label="HKD" value="HKD"></el-option>
              <el-option label="EUR" value="EUR"></el-option>
              <el-option label="GBP" value="GBP"></el-option>
              <el-option label="JPY" value="JPY"></el-option>
              <el-option label="SGD" value="SGD"></el-option>
              <el-option label="AUD" value="AUD"></el-option>
              <el-option label="CAD" value="CAD"></el-option>
              <el-option label="NZD" value="NZD"></el-option>
              <el-option label="SEK" value="SEK"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请输入付款金额:" prop="payment">
            <el-input-number
              v-model="ruleForm.payment"
              :min="0"
              :precision="ruleForm.paymentCurrency === 'JPY' ? 0 : 2"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="上传交易明细:" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :action="this.fileAction"
              :headers="uploadHeader"
              :multiple="false"
              :limit="1"
              :file-list="fileList"
              accept=".xls,.xlsx"
              :on-success="(response, file) => handleSuccess(response, file)"
              :on-exceed="handleExceed"
              :before-upload="beforeFileUpload"
              :on-remove="handleRemove"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传xlsx、xls文件，无大小限制
              </div>
            </el-upload>
            <a :href="downloadUrl">下载交易明细模板</a>
          </el-form-item>
          <el-form-item label="上传付款材料" prop="payFileUrl">
            <el-upload
              class="upload-demo"
              :action="this.fileAction"
              :headers="uploadHeader"
              :multiple="false"
              :limit="1"
              :file-list="payFileList"
              accept=".zip,.7z,.rar"
              :on-success="response => handlePaySuccess(response)"
              :on-exceed="handlePayExceed"
              :before-upload="beforePayFileUpload"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传zip、rar、7z，不超过50M
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="handleClickSubmit"
            :disabled="allowSubmit"
          >
            提 交
          </el-button>
          <el-button @click="handleShowClose">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage';
import config from '@/config';
import {
  addFundExit,
  queryFundExitList,
  querySettlementList,
  recharge,
} from 'views/main/withdraw/api';
import { ERR_OK } from '@/utils/request';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { downloadFileUrl } from '@/api';

export default {
  name: 'cross-capital',
  data() {
    return {
      statusList: [
        { value: 1, text: '新建' },
        { value: 2, text: '校验通过' },
        { value: 3, text: '校验不通过' },
        { value: 4, text: '校验失败' },
        { value: 5, text: '未支付' },
        { value: 6, text: '已支付' },
        { value: 7, text: '已取消' },
        { value: 8, text: '处理中' },
        { value: 9, text: '成功' },
        { value: 10, text: '失败' },
      ],
      fileAction: `${config.baseApi}/file-api/file-server/saas/add`,
      uploadHeader: { Authorization: storage.getItem('token') },
      tableData: [],
      showDetail: false,
      accountList: [],
      ruleForm: {
        payee: '',
        paymentCurrency: '',
        payment: '',
        fileUrl: '',
        payFileUrl: '',
        companyId: '',
      },
      submitForm: {
        serialNumber: '',
        payee: '',
        paymentMin: '',
        paymentMax: '',
      },
      pagination: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      allowSubmit: true,
      payFileList: [],
      fileList: [],
      isShowExitFunds: true,
      rules: {
        payee: [
          { required: true, message: '请选择最终收款人', trigger: 'change' },
        ],
        paymentCurrency: [
          { required: true, message: '请选择付款币种', trigger: 'change' },
        ],
        payment: [
          { required: true, message: '请填写付款金额', trigger: 'blur' },
        ],
        fileUrl: [{ required: true, message: '请上传交易明细' }],
        payFileUrl: [{ required: true, message: '请上传付款材料' }],
      },
      downloadUrl: '',
      tableLoading: false,
      fileRaw: {},
      payHtml: '',
    };
  },
  methods: {
    downloadFileUrl,
    handleCurrentChange(pageNum) {
      this.pagination = { ...this.pagination, pageNum: pageNum };
      this.handleClickSearch();
    },
    handleClickSearch() {
      this.getTableList();
    },
    async getTableList() {
      const _params = { ...this.pagination, ...this.submitForm };
      delete _params['total'];
      this.tableLoading = true;
      const { dataSet, pages } = await queryFundExitList(_params);
      dataSet.forEach(
        (o, i) => (o['index_'] = (pages.pageNum - 1) * 10 + i + 1),
      );
      this.tableData = dataSet;
      this.tableLoading = false;
      this.pagination = pages;
    },
    handleClickSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const params = new FormData();
          params.append('file', this.fileRaw.raw);
          params.append('payee', this.ruleForm.payee);
          params.append('paymentCurrency', this.ruleForm.paymentCurrency);
          params.append('payment', this.ruleForm.payment);
          params.append('fileUrl', this.ruleForm.fileUrl);
          params.append('payFileUrl', this.ruleForm.payFileUrl);
          params.append('companyId', this.$store.state.companyId);
          await addFundExit(params);
          this.$message.success('操作成功');
          this.handleClickSearch();
          this.handleShowClose();
        }
      });
    },
    handleShowClose() {
      this.showDetail = false;
      this.allowSubmit = true;
      this.payFileList = [];
      this.fileList = [];
      this.$refs.ruleForm.resetFields();
    },
    handlePaySuccess(response) {
      if (response.code !== ERR_OK) {
        this.$message.error(response.dataSet || this.$t('do_payment_title18'));
        this.payFileList = [];
        this.ruleForm = { ...this.ruleForm, payFileUrl: '' };
      }
      this.ruleForm = {
        ...this.ruleForm,
        payFileUrl: response.dataSet.path,
      };
    },
    handlePayExceed(file, fileList) {
      if (fileList.length > 0) {
        this.$message.warning(this.$t('do_payment_title16'));
      }
    },
    async handleSuccess(response, file) {
      if (response.code !== ERR_OK) {
        this.$message.error(response.dataSet || this.$t('do_payment_title18'));
        this.fileList = [];
        this.ruleForm = { ...this.ruleForm, fileUrl: '' };
        return false;
      }
      this.ruleForm = {
        ...this.ruleForm,
        fileUrl: response.dataSet.path,
      };
      this.fileRaw = file;
      const params = new FormData();
      params.append('file', file.raw);
      await axios
        .post(`${config.baseApi}/exchange-api/withdraw/checkFile`, params, {
          responseType: 'blob',
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(response => {
          if (response.headers['content-type'].includes('application/json')) {
            response.data.text().then(textData => {
              const jsonData = JSON.parse(textData);
              this.$message.success(jsonData.msg);
              this.allowSubmit = false;
            });
          } else {
            let fileName = decodeURIComponent(
              response.headers['content-disposition'].split('filename=')[1],
            );
            fileDownload(response.data, fileName);
            this.$message.error('校验失败，请查看已下载的文件');
            this.allowSubmit = true;
          }
        });
    },
    handleExceed(file, fileList) {
      if (fileList.length > 0) {
        this.$message.warning(this.$t('do_payment_title16'));
      }
    },
    handleRemove(file, fileList) {
      if (fileList.length === 0) {
        this.allowSubmit = true;
      }
    },
    beforeFileUpload(file) {
      const fileName = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['xlsx', 'xls'];
      if (whiteList.indexOf(fileName) === -1) {
        this.$message.warning('只能上传xlsx、xls文件');
        return false;
      }
    },
    beforePayFileUpload(file) {
      const fileName = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['zip', 'rar', '7z'];
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (whiteList.indexOf(fileName) === -1) {
        this.$message.warning('只能上传zip、rar、7z文件');
        return false;
      }
      if (!isLt2M) {
        this.$message.warning('上传文件不能超过50MB');
        return false;
      }
    },
    async handleClickTopUp(scope) {
      const res = await recharge({
        id: scope.row.id,
      });
      this.payHtml = res;
      const decodedRes = this.payHtml
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<');
      const formStartIndex = decodedRes.indexOf('<form');

      if (formStartIndex !== -1) {
        const formContent = decodedRes.substring(formStartIndex);

        const blob = new Blob(
          [
            '<html><body onLoad="javascript:document.E_FORM.submit()">' +
              formContent,
          ],
          { type: 'text/html' },
        );
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        await this.getTableList();
      }
    },
    cancel() {
      this.isShowExitFunds = true;
    },
    handleFundCross() {
      this.isShowExitFunds = false;
    },
    async searchFinallyPayee() {
      const params = {
        companyId: this.$store.state.companyId || '',
      };
      this.accountList = await querySettlementList(params);
    },
  },
  created() {
    const env = process.env.NODE_ENV;

    if (['development', 'production'].includes(env)) {
      this.downloadUrl = '/template.xlsx';
    } else {
      this.downloadUrl = '/exchange/template.xlsx';
    }
    this.searchFinallyPayee();
    this.getTableList();
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  width: 50%;
  .clearfix {
    text-align: center;
  }
}
</style>
