import { commonCheck, commonReg, commonReg1 } from '@/utils/utils';

export default {
  data() {
    const commonCheck2 = (rule, value, callback) => {
      if (value === '') {
        return callback();
      } else {
        if (!commonReg.test(value)) {
          return callback(new Error(this.$t('COMMON_ERR2')));
        }
      }
      return callback();
    };
    const commonCheck3 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('COMMON_ERR1')));
      }
      if (!commonReg1.test(value)) {
        return callback(new Error(this.$t('COMMON_ERR2')));
      }
      callback();
    };
    const checkSwiftCode = (rule, value, callback) => {
      const reg = /^([A-Z0-9]{8}|[A-Z0-9]{11})$/;
      if (!value) {
        return callback(new Error(this.$t('COMMON_ERR1')));
      }
      if (!reg.test(value)) {
        return callback(new Error(this.$t('COMMON_ERR2')));
      }
      callback();
    };

    return {
      currentType: 1,
      rules: {
        accountName: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        accountBank: [
          {
            validator: commonCheck2,
            trigger: 'blur',
          },
        ],
        bankAddress: [
          {
            validator: commonCheck2,
            trigger: 'blur',
          },
        ],
        middleBankName: [
          {
            validator: commonCheck2,
            trigger: 'blur',
          },
        ],
        accountNo: [
          {
            validator: commonCheck3,
            required: true,
            trigger: 'blur',
          },
        ],
        swiftCode: [
          { required: true, validator: checkSwiftCode, trigger: 'blur' },
        ],
        area: [
          { required: true, message: this.$t('COMMON_ERR4'), trigger: 'blur' },
        ],
        address: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        receiverName: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        // fileUrl: [
        //   {
        //     required: this.currentType === 1,
        //     message: '不可为空',
        //     trigger: 'blur',
        //   },
        // ],
      },
      // 国家地区
      country_list: [
        { txt: '中华人民共和国', value: 'CHN' },
        { txt: '中国香港特别行政区', value: 'HKG' },
        { txt: '中国澳门特别行政区', value: 'MAC' },
        { txt: '美利坚合众国', value: 'USA' },
        { txt: '欧洲', value: 'EUR' },
        { txt: '英国', value: 'GBR' },
        { txt: '日本', value: 'JPN' },
        { txt: '新西兰', value: 'NZ' },
        { txt: '瑞士', value: 'CH' },
        { txt: '新加坡', value: 'SG' },
        { txt: '澳洲', value: 'AUS' },
      ],
    };
  },
};
